.swiper-height{
    height: 450px;
}

@media(max-width:425px){
    .swiper-height{
        height: 350px;
        width: 350px;
    }
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }