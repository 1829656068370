/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;500&family=Montserrat:ital,wght@0,300;0,400;0,500;1,500&family=Roboto:wght@300;400;500;700&family=Seaweed+Script&display=swap'); */

*{
    margin: 0;
    padding: 0;
    font-family:  sans-serif;
    box-sizing: border-box;
}
input{
border:1px solid rgba(0, 0, 0, 0.1);
border-radius:4px
}
.text-addtocart-heading{
  font-size: 24px;
  font-weight: bold;
}
.mt-150{
    margin-top: 150px;
}
.link{
    text-decoration: none;
    color: black;
}
.mt-40{
    margin-top: 40px;
}
.mt-20{
    margin-top: 10px;
}
.slider-heading112{
  text-align: left;
  font-size: 40px;
  font-weight: 600;
}


 /* --------------------------navbarcsss--------------------------------- */
 .Nav-main{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    height: 100px;
    z-index: 12;
 }
 
.nav-content{
    display: flex;
    justify-content: space-between;
}
.Nav-right{
    display: flex;
    align-items: center;
}
.logo {
    width: 100px;
    height: 90px;
}
.Toggle{
    color: white;
    display: flex;
    align-items: center;
}
.bg-dark{
    background-color: rgb(26, 25, 25) !important;
}

.swiper-button-prev,.swiper-button-next{
    color: orange !important;
    margin-left: 40px;
    margin-right: 40px;
}
.swiper-pagination-bullet {
  display: none;
    background-color: orange !important;
    width: 20px;
    height: 20px;
}
.active-color{
  color: orange !important;
  /* border-bottom: 2px solid white; */
}
#navbarNav .nav-link{
    color: white;
    font-size: 18px;
    font-weight: 600;
}

 /* --------------------------sidebarcss--------------------------------- */
 
 .list-items11221 li{
     padding: 10px 0px;
     font-size: 18px;
     font-weight: bold;
    }
    
    
    /* --------------------------footer--------------------------------- */
 .footer12{
  width: 100%;
        background-color: gainsboro;
        color: black;
}
.footer12 h3 {
  font-size: 16px;
  font-weight: 600;
  color: #080808;
}
.footer12 p {
  font-weight: 500;
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 14px;
}
.round{
  border-radius: 25rem;
}
@media screen and (max-width: 768px) {
  .round{
    border-radius: 10px;
  }
  .text-addtocart-heading{
    font-size: 18px;
  }
}
.footer12 li{
text-decoration: none;
list-style: none;
/* font-size: 16px; */
/* font-weight: bold; */
color: rgb(255, 255, 255);
}
.link-style2{
text-decoration: none;
list-style: none;
font-size: 13px;
font-weight: 600;
color: rgb(23, 164, 215);
}
.footer12 span{
padding: 0px 10px;
font-size: 30px;
}
.card-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50px;
  width: 90px; */
  margin: 10px 0px;
  /* border-radius: 8px; */
  /* background-color: white; */
padding: 0px 5px;
}

 /* .img-logo12{
  width: 20%;
} */
/* .card-footer img{
  width: 90%;
} */
/* @media (max-width: 768px){
  .card-footer{
    height: 30px;
  width: 50px;
  }
  
} */


/* --------------------------footer--------------------------------- */
 
/* UserInfoForm.css */
.lab-inpt input{
margin-bottom:8px;
}
.sign-up input{
  margin: 5px 0px;
  padding: 12px 8px;
}


.form1232 {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form1232  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form1232  {
    display: flex;
    flex-direction: column;
  }
  
  .form1232  label {
    display: block;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
  }
  
  .form1232  input {
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form1232  button {
    float: right;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .bar-color div {
    background-color: orange !important;
  }
  .form1232  button:hover {
    background-color: #0056b3;
  }
  
  /* Media Query for small screens */
  @media (max-width: 768px) {
    .form1232 {
      max-width: 100%;
    }
    .slider-heading112{
      font-size: 25px;
    }
    .swiper-button-prev,.swiper-button-next{
      color: orange !important;
      margin-left: 20px;
      margin-right: 20px;
  }
  }
  @media (max-width: 480px) {
    .text-addtocart-heading{
      font-size: 14px;
    }
    .form1232 {
      max-width: 100%;
    }
    .slider-heading112{
      font-size: 17px;
    }
    .swiper-button-prev,.swiper-button-next{
      color: orange !important;
      margin-left: 0px;
      margin-right: 0px;
  }
  .swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
}
.swiper-pagination-bullet {
  background-color: orange !important;
  width: 10px;
  height: 10px;
}
  }

  /* ...............sectionthree-card-style.................. */

  .customStyle-card-dynamic {
    width: 90%;
    height: 15rem;
    padding: 1rem;
    font-size: 3.25rem;
    border: none;
    border-radius: 0.5rem;
    background-color:  gainsboro;
    color: #181818;
    border-color: transparent;
    border-width: 3px;
    border-style: solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: all 0.5s linear;
    cursor: pointer;
  }
  
  .customStyle-card-dynamic:hover {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 4rem;
    width: 100%;
    height: 15.4rem;
    background-color: orange;
    color: white;
    /* border-color: rgb(118, 115, 115); */
  }
  
  .customStyle-card-dynamic:hover i {
    background-color: #faa499;
    background-image: linear-gradient(319deg, #faa499 0%, #f7dd85 37%, #ffc55c 100%);
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color: transparent;
  
  }
  
  .customStyle-card-title-dynamic {
    font-size: 17px;
    font-weight: 600;
    margin-top: 0.5rem;
    text-align: center;
  }
  
  .customStyle-card-text-dynamic {
    font-size: 0.75rem;
    text-align: center;
  }
  .customStyle-card-text-dynamic:hover {
    font-size: 1rem;
    text-align: center;
  }
  
  .customStyle-card-body-dynamic {
    padding: 0.25rem;
  }

  /* ...............sectionthree-card-style.................. */



  /* ...............Donate-card-style.................. */
  .cart {
    width: 97%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  }
  .cart-img{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
 

  
  .cart-heading {
    /* font-size: 1.3rem; */
    font-weight: 600;
    margin-bottom: 5px;
  }
  .cart-subheading {
    font-size: 0.8rem;
    font-weight: 500;
    /* margin-bottom: 5px; */
  }
  
  .cart-description {
    color: gray;
    font-size: 0.8rem;
    font-weight: 600;
    /* margin-bottom: 15px; */
  }
  
  
  .cart-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .cart-button:hover {
    background-color: #0056b3;
  }
  
  /* ...............Donate-card-style.................. */
  /* ...............DonateProfile-card-style.................. */

.yourdonation-image{
width: 50%;
}

/* @media (max-width: 768px){
  .imgprofile img{
    width: 350px;
    height: 300px;
    }
} */
@media (max-width: 768px){
  .yourdonation-image{
    width: 80%;
    }
}
/* @media (max-width: 768px){
  .yourdonation{
    width: 100%;
    }
} */
  
  /* ...............DonateProfile-card-style.................. */
  /* ...............DonateProfile-card-style.................. */

  .cart-text{
    height: 350px;
    overflow-y: hidden;
    border-radius: 8px;
    text-align: left;
    margin: 10px 5px;
    padding: 9px 8px;
    transition: all 0.5s linear;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1);
  }
  .cart-text:hover{
    height: 370px;
    color: white;
    background-color: orange;
  }

.imgprofile img{
width: 400px;
}

@media (max-width: 1024px){
  .cart-text:hover{
    height: 440px;
  }
}
@media (max-width: 768px){
  .cart-text{
    height: 200px;
  }
  .cart-text:hover{
    height: 200px;
  }
}
@media (max-width: 425px){
  .cart-text{
    height: 300px;
  }
  .cart-text:hover{
    height: 320px;
  }
}
@media (max-width: 375px){
  .imgprofile img{
    width: 100%;
    }
    .cart-text{
      height: 350px;
    }
    .cart-text:hover{
      height: 380px;
    }
}
@media (max-width: 320px){
 
    .cart-text{
      height: 350px;
    }
    .cart-text:hover{
      height: 440px;
    }
}
  
  /* ...............DonateProfile-card-style.................. */